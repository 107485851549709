import { render } from "./Input.vue?vue&type=template&id=2d3bd085&scoped=true"
import script from "./Input.vue?vue&type=script&lang=js"
export * from "./Input.vue?vue&type=script&lang=js"

import "./Input.vue?vue&type=style&index=0&id=2d3bd085&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-2d3bd085"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2d3bd085"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2d3bd085', script)) {
    api.reload('2d3bd085', script)
  }
  
  module.hot.accept("./Input.vue?vue&type=template&id=2d3bd085&scoped=true", () => {
    api.rerender('2d3bd085', render)
  })

}

script.__file = "src/page/b2b/components/Input.vue"

export default script