<template>
  <div id="tutoringContent">
    <div class="banner">
      <div class="inner">
        <div class="main__desc">
          기업이 원하는 학습 방법과 환경에 맞는
          <br />
          다각화된 어학 서비스 플랫폼을 만나보세요
        </div>
        <div class="main__desc mobile">
          기업이 원하는
          <br />
          학습 방법과 환경에 맞는
          <br />
          다각화된 어학 서비스
          <br />
          플랫폼을 만나보세요
        </div>
        <div class="sub__desc">시공간의 제약없이 만나는 링고라 글로벌 기업교육</div>
        <div class="sub__desc mobile">
          시공간의 제약없이 만나는
          <br />
          링고라 글로벌 기업교육
        </div>
      </div>
    </div>
    <section class="company">
      <div class="select__tab">
        <button class="button" @click="scrollToMobile">
          <div class="title">
            링고라
            <br />
            모바일
          </div>
          <div class="shortcut">
            <div class="text">바로가기</div>
            <i class="arrow-icon"></i>
          </div>
        </button>
        <button class="button" @click="scrollToLecture">
          <div class="title">
            링고라
            <br />
            출강
          </div>
          <div class="shortcut">
            <div class="text">바로가기</div>
            <div class="icon"></div>
          </div>
        </button>
      </div>
      <div class="company__title">
        글로벌 핵심인재를 육성하는 국내 50대 그룹사부터
        <br />
        전 임직원의 어학 실력향상이 필요한 기업까지
      </div>
      <div class="company__title mobile">
        글로벌 핵심인재를 육성하는
        <br />
        국내 50대 그룹사부터
        <br />
        전 임직원의 어학 실력향상이
        <br />
        필요한 기업까지
      </div>
      <div class="company__desc">빠른 성장을 이루고 있는 회사는 이미 링고라의 B2B 기업교육을 함께하고 있습니다.</div>
      <div class="company__desc mobile">
        빠른 성장을 이루고 있는 회사는
        <br />
        이미 링고라의 B2B 기업교육을 함께하고 있습니다.
      </div>
      <div class="logo"></div>
      <div class="logo mobile" :style="logoHeight"></div>
      <div class="more" @click="moreLogo">
        <div class="more__icon" :style="{ transform: moreIconTransform }"></div>
      </div>
    </section>
    <section class="mobile-section" id="mobile-section">
      <div class="contents-wrap">
        <div class="number">01</div>
        <div class="text">링고라 모바일</div>
        <div class="title__desc">
          <p>하나의 서비스로</p>
          <p>원어민 회화 + AI 학습이 모두 가능합니다</p>
        </div>
        <div class="title__desc mobile">
          하나의 서비스로
          <br />
          원어민 회화 + AI 학습이
          <br />
          모두 가능합니다
        </div>
        <div class="sub__desc">
          단일 모바일 서비스에서 원어민 회화와 AI 학습이
          <br />
          동시에 가능한 서비스는 오직 링고라 뿐입니다.
        </div>
        <img class="img11" src="//res.tutoring.co.kr/res/images/web/b2b/img_b2b_11.png" />
      </div>
      <div class="contents-wrap">
        <div class="title__desc">24시간 언제든 수업이 가능합니다</div>
        <div class="title__desc mobile">
          24시간 언제든
          <br />
          수업이 가능합니다
        </div>
        <div class="sub__desc">
          학습자가 원하는 시간에
          <br />
          언제든 즉시 또는 예약을 통해 원어민과 수업을 할 수 있으며,
          <br />
          AI 학습은 24시간 무제한 학습이 가능합니다.
        </div>
        <img class="img12" src="//res.tutoring.co.kr/res/images/web/b2b/img_b2b_12.png" />
      </div>
      <div class="contents-wrap">
        <div class="title__desc">학습 목표에 맞는 커리큘럼을 제공합니다</div>
        <div class="title__desc mobile">
          학습 목표에 맞는
          <br />
          커리큘럼을 제공합니다
        </div>
        <div class="sub__desc">
          여행, 비즈니스, 어학시험 등
          <br />
          나만의 커리큘럼을 설계해 보세요.
        </div>
        <img class="img13" src="//res.tutoring.co.kr/res/images/web/b2b/img_b2b_13.gif" />
      </div>
      <div class="contents-wrap">
        <div class="title__desc">체계적인 학습 피드백을 제공합니다.</div>
        <div class="title__desc mobile">
          체계적인 학습
          <br />
          피드백을 제공합니다.
        </div>
        <div class="sub__desc">
          기존의 원어민 튜터의 피드백에서 그치지 않고
          <br />
          AI 기술을 통한 실시간 문장 교정 및 추천, 첨삭, 정밀 분석 등
          <br />
          섬세하고, 체계적인 학습 피드백을 제공합니다.
        </div>
        <div class="img__desc">
          <img class="img14" src="//res.tutoring.co.kr/res/images/web/b2b/img_b2b_14.png" />
          <img class="img15" src="//res.tutoring.co.kr/res/images/web/b2b/img_b2b_15.png" />
        </div>
      </div>
      <div class="contents-wrap">
        <div class="title__desc">
          데이터 기반의 객관적인
          <br />
          AI 레벨테스트를 제공합니다.
        </div>
        <div class="title__desc mobile">
          데이터 기반의 객관적인
          <br />
          AI 레벨테스트를 제공합니다.
        </div>
        <div class="sub__desc">
          25,000개의 데이터 기반으로 나의 현재
          <br />
          수준과 향상도를 정확히 체크할 수 있습니다.
        </div>
        <div class="img__desc">
          <img class="img16" src="//res.tutoring.co.kr/res/images/web/b2b/img_b2b_16.png" />
          <img class="img17" src="//res.tutoring.co.kr/res/images/web/b2b/img_b2b_17.png" />
        </div>
      </div>
      <div class="contents-wrap">
        <div class="title__desc">AI 학습 튜토리얼</div>
        <div class="title__desc mobile">AI 학습 튜토리얼</div>
        <div class="sub__desc">
          링고라 AI에게 맡기기만 하면 영어 회화 실력은 자동 향상!
          <br />
          지금 바로 AI 1:1 영어 코치를 만나보세요.
        </div>
        <video
          ref="demoVideo"
          class="video_desc"
          loop
          playsinline
          muted
          controls
          src="https://res.tutoring.co.kr/res/images/web/b2b/lingoraAI_Demo.mp4"
        />
      </div>
      <div class="contact-wrap">
        <button class="contact-button" @click="scrollToContact">기업교육 문의하기</button>
      </div>
    </section>
    <section class="lecture" id="lecture-section">
      <div class="contents-wrap">
        <div class="number">02</div>
        <div class="text">링고라 출강</div>
        <div class="title__desc">링고라가 하면 다릅니다, 핵심 전략 3요소</div>
        <div class="title__desc mobile">
          링고라가 하면 다릅니다,
          <br />
          핵심 전략 3요소
        </div>
      </div>
      <div class="container">
        <div class="container__wrapper">
          <div class="detail">
            <div class="title">
              꼼꼼한
              <br />
              학습자 관리
            </div>
            <ul>
              <li>출결 데이터 관리</li>
              <li>학습독려 및 동기부여</li>
              <li>향상도 평가 및 피드백</li>
              <li>이슈사항 처리</li>
            </ul>
          </div>
          <div class="detail">
            <div class="title">
              엄격한
              <br />
              강사 품질 관리
            </div>
            <ul>
              <li>강사 근태관리</li>
              <li>수업참관 및 강사 피드백</li>
              <li>교육 환경 조성</li>
              <li>정기적인 강사 보수 교육</li>
              <li>소속감 및 책임감 부여</li>
            </ul>
          </div>
          <div class="detail">
            <div class="title">
              탁월한
              <br />
              커리큘럼 관리
            </div>
            <ul>
              <li>학습자 니즈 및 레벨링에 따른 맞춤 교수 설계</li>
              <li>교육 콘텐츠 맞춤 개발 제공</li>
              <li>실무에 바로 적용할 콘텐츠 도입</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="introduce__body">
          <div class="introduce__body__tab__wrapper">
            <div class="introduce__body__tab" :style="{ left: `${curTabPosition}px` }">
              <div class="introduce__body__tab__item" :class="{ active: isThisCurTab(1) }" @click="onClickTab(1)">
                Special Blended
                <br />
                Course
              </div>
              <div class="introduce__body__tab__item" :class="{ active: isThisCurTab(2) }" @click="onClickTab(2)">
                Hybrid Course
              </div>
              <div class="introduce__body__tab__item" :class="{ active: isThisCurTab(3) }" @click="onClickTab(3)">
                Business & General
                <br />
                Conversation Course
              </div>
              <div class="introduce__body__tab__item" :class="{ active: isThisCurTab(4) }" @click="onClickTab(4)">
                1:1 Coaching
              </div>
              <div class="introduce__body__tab__item" :class="{ active: isThisCurTab(5) }" @click="onClickTab(5)">
                Biz Skills Course
              </div>
            </div>
          </div>
          <div class="introduce__body__content__wrapper">
            <swiper
              class="introduce__swiper"
              :loop="true"
              :slidesPerView="1"
              :centeredSlides="true"
              effect="fade"
              :fadeEffect="{ crossFade: true }"
              @slideChange="onSlideChange"
              @swiper="onIntroduceSwiper"
            >
              <swiper-slide>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="introduce__content">
                      <div class="introduce__image introduce__image--00"></div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="introduce__content">
                      <div aria-hidden="true" class="introduce__image introduce__image--01"></div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="introduce__content">
                      <div class="introduce__image introduce__image--02"></div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="introduce__content">
                      <div class="introduce__image introduce__image--03"></div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="introduce__content">
                      <div class="introduce__image introduce__image--04"></div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>
    <section class="service">
      <div class="service__title">교육 담당자를 위한 철저한 맞춤 운영 관리 서비스</div>
      <div class="service__title mobile">
        교육 담당자를 위한
        <br />
        철저한 맞춤 운영 관리 서비스
      </div>
      <div class="service__desc">전담 매니저의 1:1 밀착 관리, 효과적인 학습 레포트 제공까지</div>
      <div class="service__desc mobile">
        전담 매니저의 1:1 밀착 관리,
        <br />
        효과적인 학습 레포트 제공까지
      </div>
      <div class="service__wrap">
        <div class="item__wrapper">
          <div class="service__item">
            <div class="title">
              체계적인 설계
              <br />
              & 맞춤 커리큘럼
            </div>
            <div class="desc">
              교육 효과를 높이는 교수 설계와 고객사 특성을 접목한 글로벌 교육 맞춤 기획을 진행합니다.
            </div>
            <img src="//res.tutoring.co.kr/res/images/web/b2b/ic_focus.png" />
          </div>
          <div class="service__item">
            <div class="title">
              전담
              <br />
              매니저
            </div>
            <div class="desc">기업 단체에 필요한 맞춤 교육 서비스 운영을 전담 매니저가 직접 관리합니다.</div>
            <img src="//res.tutoring.co.kr/res/images/web/b2b/ic_manager.png" />
          </div>
        </div>
        <div class="item__wrapper">
          <div class="service__item">
            <div class="title">
              링고라만의
              <br />
              학습 케어
            </div>
            <div class="desc">더욱 효과적인 학습을 위한 Learning Mate System을 운영합니다</div>
            <img src="//res.tutoring.co.kr/res/images/web/b2b/ic_care.png" />
          </div>
          <div class="service__item">
            <div class="title">
              효과적인
              <br />
              학습 레포트
            </div>
            <div class="desc">학습 전/후 레벨 테스트를 통해 보다 객관적으로 실력 항상도를 확인할 수 있습니다.</div>
            <img src="//res.tutoring.co.kr/res/images/web/b2b/ic_report.png" />
          </div>
        </div>
      </div>
    </section>
    <section class="pride">
      <p class="pride-title">
        <strong class="pride-title__no1">5년 연속 브랜드 대상 1위</strong>
        소비자가 직접 뽑은 1:1 영어회화 부문
      </p>
      <img class="img" src="//res.tutoring.co.kr/res/images/evt/com/award_set_2023.png" alt="2023 퍼스트 브랜드 대상" />
      <ul class="pride__img">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </section>
    <section class="contact" id="contact-section">
      <div class="contact__title">
        부담없이 지금 바로
        <br />
        문의를 남겨주세요.
      </div>
      <div class="contact__desc">링고라 B2B 서비스가 함께 하겠습니다.</div>

      <article class="b2b-booking" id="b2bBooking">
        <div id="b2bForm" class="b2b-form">
          <fieldset class="b2b-form__wrapper">
            <div class="b2b-form__dropdown" :class="{ open: activeDropdown === 'product' }" ref="productDropdown">
              <button
                type="text"
                class="dropdown-main"
                :class="{ 'not-selected': product === '문의 교육 상품', 'error-border': isErrorState.product }"
                style="margin-bottom: 0"
                @click="toggleDropdown('product')"
              >
                {{ product }}
              </button>
              <p class="error-text" v-if="isErrorState.product">문의할 교육 상품을 선택해 주세요.</p>
              <ul class="dropdown-list">
                <li class="item" v-for="item in products" :key="item.value">
                  <button
                    type="text"
                    class="dropdown-sub"
                    @click="selectProduct(item.value)"
                    :class="{ selected: product === item.value }"
                  >
                    {{ item.label }}
                  </button>
                </li>
              </ul>
            </div>
            <div
              class="b2b-form__dropdown"
              :class="{ open: activeDropdown === 'expectedParticipant' }"
              ref="expectedParticipantPropdown"
            >
              <button
                type="text"
                class="dropdown-main"
                style="margin-bottom: 0"
                :class="{
                  'not-selected': expectedParticipant === '예상 교육 인원',
                  'error-border': isErrorState.expectedParticipant,
                }"
                @click="toggleDropdown('expectedParticipant')"
              >
                {{ expectedParticipant }}
              </button>
              <p class="error-text" v-if="isErrorState.expectedParticipant">예상되는 교육 인원을 선택해 주세요.</p>
              <ul class="dropdown-list">
                <li class="item" v-for="item in expectedParticipants" :key="item.value">
                  <button
                    type="text"
                    class="dropdown-sub"
                    @click="selectExpectedParticipant(item.value)"
                    :class="{ selected: expectedParticipant === item.value }"
                  >
                    {{ item.label }}
                  </button>
                </li>
              </ul>
            </div>
            <div
              class="b2b-form__dropdown"
              :class="{ open: activeDropdown === 'companyType' }"
              ref="companyTypeDropdown"
            >
              <button
                type="text"
                class="dropdown-main"
                style="margin-bottom: 0"
                :class="{ 'not-selected': companyType === '기업 유형', 'error-border': isErrorState.companyType }"
                @click="toggleDropdown('companyType')"
              >
                {{ companyType }}
              </button>
              <p class="error-text" v-if="isErrorState.companyType">기업 유형을 선택해 주세요.</p>
              <ul class="dropdown-list">
                <li class="item" v-for="item in companyTypes" :key="item.value">
                  <button
                    type="text"
                    class="dropdown-sub"
                    @click="selecCompanyType(item.value)"
                    :class="{ selected: companyType === item.value }"
                  >
                    {{ item.label }}
                  </button>
                </li>
              </ul>
            </div>
            <!-- 새로운 input data들-->
            <div class="b2b-form__item">
              <Input
                :value="company"
                :placeholder="'회사명'"
                @input="onChangeCompany"
                @blur="onBlurCompany"
                @click="disabledDropdown"
                :isError="isErrorState.company"
              />
              <p class="error-text" v-if="isErrorState.company">회사명을 입력해 주세요.</p>
            </div>
            <div class="b2b-form__item">
              <Input
                :value="name"
                :placeholder="'이름'"
                @input="onChangeName"
                @blur="onBlurName"
                @click="disabledDropdown"
                :isError="isErrorState.name"
              />
              <p class="error-text" v-if="isErrorState.name">이름을 입력해 주세요.</p>
            </div>
            <div class="b2b-form__item">
              <Input
                :value="phone"
                :placeholder="'전화번호 (e.g. 01012345678)'"
                @input="onChangePhone"
                @blur="onBlurPhone"
                @click="disabledDropdown"
                :isError="isErrorState.phone"
              />
              <p class="error-text" v-if="isErrorState.phone">올바른 형식의 전화번호를 입력해 주세요.</p>
            </div>
            <div class="b2b-form__item">
              <Input
                :value="email"
                :placeholder="'이메일 (원활한 안내를 위해 업무용 이메일을 입력해 주세요.)'"
                @input="onChangeMail"
                @blur="onBlurEmail"
                @click="disabledDropdown"
                :isError="isErrorState.email"
              />
              <p class="error-text" v-if="isErrorState.email">유효한 이메일 주소를 입력해 주세요.</p>
            </div>
            <div
              class="b2b-form__dropdown"
              :class="{ open: activeDropdown === 'expectedStartDate' }"
              ref="expectedStartDateDropdown"
            >
              <button
                type="text"
                class="dropdown-main"
                style="margin-bottom: 0"
                :class="{ 'not-selected': expectedStartDate === '예상 교육 시작일 (선택항목)' }"
                @click="toggleDropdown('expectedStartDate')"
              >
                {{ expectedStartDate }}
              </button>
              <ul class="dropdown-list">
                <li class="item" v-for="item in expectedStartDates" :key="item.value">
                  <button
                    type="text"
                    class="dropdown-sub"
                    @click="selectExpectedStartDate(item.value)"
                    :class="{ selected: expectedStartDate === item.value }"
                  >
                    {{ item.label }}
                  </button>
                </li>
              </ul>
            </div>
            <div class="b2b-form__dropdown" :class="{ open: activeDropdown === 'department' }" ref="departmentDropdown">
              <button
                type="text"
                class="dropdown-main"
                style="margin-bottom: 0"
                :class="{ 'not-selected': department === '담당 업무 (선택항목)' }"
                @click="toggleDropdown('department')"
              >
                {{ department }}
              </button>
              <ul class="dropdown-list">
                <li class="item" v-for="item in departments" :key="item.value">
                  <button
                    type="text"
                    class="dropdown-sub"
                    @click="selectDepartment(item.value)"
                    :class="{ selected: department === item.value }"
                  >
                    {{ item.label }}
                  </button>
                </li>
              </ul>
            </div>
            <div class="b2b-form__dropdown" :class="{ open: activeDropdown === 'motivation' }" ref="motivationDropdown">
              <button
                type="text"
                class="dropdown-main"
                style="margin-bottom: 0"
                :class="{ 'not-selected': motivation === '링고라를 알게된 계기 (선택항목)' }"
                @click="toggleDropdown('motivation')"
              >
                {{ motivation }}
              </button>
              <ul class="dropdown-list">
                <li class="item" v-for="item in motivations" :key="item.value">
                  <button
                    type="text"
                    class="dropdown-sub"
                    @click="selectMotivation(item.value)"
                    :class="{ selected: motivation === item.value }"
                  >
                    {{ item.label }}
                  </button>
                </li>
              </ul>
            </div>
            <div class="b2b-form__item2">
              <label for="form-letter" class="b2b-form__label">문의 내용</label>
              <textarea
                id="form-letter"
                placeholder="간단한 문의 사항을 작성해 주세요."
                class="b2b-form__textarea"
                @input="onChangeContent"
                @blur="onBlurContent"
                @click="disabledDropdown"
                :isError="isErrorState.content"
              ></textarea>
              <p class="error-text" v-if="isErrorState.content">문의 내용을 입력해 주세요.</p>
            </div>
            <div class="b2b-form__item3">
              <label for="form-letter" class="b2b-form__label">개인정보 수집 동의</label>
              <label for="form-letter" class="b2b-form__desc">
                개인정보 취급방침에 따라 제공해주신 정보를 처리하는 것에 동의합니다.
              </label>
              <div class="b2b-form__confirm" @click="onClickTermAgree">
                <!-- <input type="checkbox" id="form-confirm" class="b2b-form__checkbox"  /> -->
                <CheckBox :type="'block'" :checked="isTermChecked" />
                <label for="form-confirm" class="b2b-form__label">동의합니다.</label>
              </div>
            </div>
            <div class="b2b-form__item3" style="margin-top: -20px">
              <div style="display: flex; gap: 4px">
                <label for="form-letter" class="b2b-form__label">마케팅 수신 동의</label>
                <label for="form-letter" class="b2b-form__short">(선택항목)</label>
              </div>
              <label for="form-letter" class="b2b-form__desc">
                동의 시, 무료 교육 자료 발간 및 행사 소식을 가장 먼저 받아보실 수 있습니다.
              </label>
              <div class="b2b-form__confirm" @click="onMarketingConsent()">
                <!-- <input type="checkbox" id="form-confirm" class="b2b-form__checkbox"  /> -->
                <CheckBox :type="'block'" :checked="isMarketingChecked === true" />
                <label for="form-confirm" class="b2b-form__label">동의합니다.</label>
              </div>
            </div>
            <button
              class="b2b-form__submit"
              :class="{ disabled: isError }"
              :disabled="isError"
              @click="onClickAplySubmitBtn"
            >
              문의하기
            </button>
          </fieldset>
        </div>
      </article>
    </section>
  </div>
  <div class="bottom-btn" @click="scrollToContact">10초만에 기업교육 문의하기</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import Input from './components/Input.vue';
import CheckBox from './components/CheckBox.vue';

export default {
  name: 'B2BProgram',
  components: {
    Swiper,
    SwiperSlide,
    Input,
    CheckBox,
  },
  data() {
    return {
      videoPlayed: false,
      curTab: 1,
      introduceSwiper: null,
      windowWidth: 0,
      activeDropdown: '',
      motivation: '링고라를 알게된 계기 (선택항목)',
      motivations: [
        { value: '검색', label: '검색' },
        { value: '광고', label: '광고' },
        { value: '메일링', label: '메일링' },
        { value: '사용 경험', label: '사용 경험' },
        { value: '지인 추천', label: '지인 추천' },
        { value: '기타', label: '기타' },
      ],
      product: '문의 교육 상품',
      products: [
        {
          value: '모바일',
          label: '모바일',
        },
        {
          value: '출강',
          label: '출강',
        },
      ],
      expectedParticipant: '예상 교육 인원',
      expectedParticipants: [
        {
          value: '30명 이하',
          label: '30명 이하',
        },
        {
          value: '50명 이하',
          label: '50명 이하',
        },
        {
          value: '100명 이하',
          label: '100명 이하',
        },
        {
          value: '200명 이하',
          label: '200명 이하',
        },
        {
          value: '그 외',
          label: '그 외',
        },
      ],
      companyType: '기업 유형',
      companyTypes: [
        {
          value: '대기업',
          label: '대기업',
        },
        {
          value: '중견기업',
          label: '중견기업',
        },
        {
          value: '중소기업',
          label: '중소기업',
        },
        {
          value: '공기업',
          label: '공기업',
        },
        {
          value: '공공기관',
          label: '공공기관',
        },
        {
          value: '학교',
          label: '학교',
        },
        {
          value: '그 외',
          label: '그 외',
        },
      ],
      expectedStartDate: '예상 교육 시작일 (선택항목)',
      expectedStartDates: [
        {
          value: '1개월 이내',
          label: '1개월 이내',
        },
        {
          value: '3개월 이내',
          label: '3개월 이내',
        },
        {
          value: '6개월 이내',
          label: '6개월 이내',
        },
        {
          value: '1년 이내',
          label: '1년 이내',
        },
        {
          value: '그 외',
          label: '그 외',
        },
      ],
      department: '담당 업무 (선택항목)',
      departments: [
        { value: 'HR(인사 관리)', label: 'HR(인사 관리)' },
        { value: 'HRD(인재 육성/교육)', label: 'HRD(인재 육성/교육)' },
        { value: '그 외', label: '그 외' },
      ],
      isSelected: '',
      moreIconTransform: 'rotate(135deg) translateY(-50%)',
      /* 신청자 정보 입력 데이터 */
      company: '',
      name: '',
      phone: '',
      email: '',
      content: '',
      /*--------------------*/
      isTermChecked: false,
      isMarketingChecked: false,
      logoHeight: {
        backgroundImage: 'url(//res.tutoring.co.kr/res/images/web/b2b/img_ref_w360.png)',
        width: '100%',
        backgroundSize: 'cover',
        height: '672px',
      },
      isErrorState: {
        company: false,
        name: false,
        phone: false,
        email: false,
        product: false,
        expectedParticipant: false,
        companyType: false,
        content: false,
      },
      isError: true,
    };
  },
  methods: {
    observeVideo() {
      const videoElement = this.$refs.demoVideo;

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.videoPlayed) {
            videoElement.play();
            this.videoPlayed = true;
          }
        });
      };

      const observer = new IntersectionObserver(callback, {
        threshold: 1.0,
      });

      observer.observe(videoElement);
    },
    handleClickOutside(event) {
      const dropdowns = [
        this.$refs.productDropdown,
        this.$refs.expectedParticipantPropdown,
        this.$refs.companyTypeDropdown,
        this.$refs.expectedStartDateDropdown,
        this.$refs.departmentDropdown,
        this.$refs.motivationDropdown,
      ];

      if (Object.values(dropdowns).every((dropdown) => !dropdown.contains(event.target))) {
        if (this.activeDropdown) {
          if (this.activeDropdown === 'product') {
            if (this.product === '문의 교육 상품') {
              this.isErrorState.product = true;
            } else {
              this.isErrorState.product = false;
            }
          }
          if (this.activeDropdown === 'expectedParticipant') {
            if (this.expectedParticipant === '예상 교육 인원') {
              this.isErrorState.expectedParticipant = true;
            } else {
              this.isErrorState.expectedParticipant = false;
            }
          }
          if (this.activeDropdown === 'companyType') {
            if (this.companyType === '기업 유형') {
              this.isErrorState.companyType = true;
            } else {
              this.isErrorState.companyType = false;
            }
          }
        }
        this.activeDropdown = ''; // 모든 드롭다운 닫기
      }
    },
    getActiveDropdownRef() {
      const dropdowns = [
        this.$refs.productDropdown,
        this.$refs.expectedParticipantPropdown,
        this.$refs.companyTypeDropdown,
        this.$refs.expectedStartDateDropdown,
        this.$refs.departmentDropdown,
        this.$refs.motivationDropdown,
      ];

      const test = Object.keys(dropdowns).find((key) => dropdowns[key] && this.activeDropdown === key);
      return test;
    },
    scrollToMobile() {
      const element = document.getElementById('mobile-section');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    scrollToLecture() {
      const element = document.getElementById('lecture-section');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    scrollToChat() {
      const element = document.getElementById('chat-section');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    scrollToContact() {
      const element = document.getElementById('contact-section');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    moreLogo() {
      if (this.logoHeight.height === '672px') {
        this.logoHeight.backgroundSize = 'contain';
        this.moreIconTransform = 'rotate(315deg) translateY(0)';
        if (window.innerWidth <= 380) {
          this.logoHeight.height = '1000px';
        } else if (window.innerWidth <= 480) {
          this.logoHeight.height = '1300px';
        }
      } else {
        this.logoHeight.height = '672px';
        this.logoHeight.backgroundSize = 'cover';
        this.moreIconTransform = 'rotate(135deg) translateY(-50%)';
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    onSlideChange(index) {
      if (index.activeIndex < 1) {
        this.changeCurrentTab(5);
        return;
      }
      if (index.activeIndex > 5) {
        this.changeCurrentTab(1);
        return;
      }
      this.changeCurrentTab(index.activeIndex);
    },
    onIntroduceSwiper(swiper) {
      this.introduceSwiper = swiper;
    },
    handleSlideTo(index) {
      this.introduceSwiper.slideTo(index);
    },
    onClickTab(tab) {
      this.handleSlideTo(tab);
      this.changeCurrentTab(tab);
    },
    changeCurrentTab(tab) {
      this.curTab = tab;
    },
    isThisCurTab(tab) {
      return tab === this.curTab;
    },
    toggleDropdown(dropdownName) {
      this.activeDropdown = this.activeDropdown === dropdownName ? '' : dropdownName;
    },
    disabledDropdown() {
      this.activeDropdown = '';
    },
    onChangeCompany(event) {
      if (!event?.target) {
        return;
      }
      this.company = event.target.value;
    },
    onChangeName(event) {
      if (!event?.target) {
        return;
      }
      this.name = event.target.value;
    },
    onChangePhone(event) {
      if (!event?.target) {
        return;
      }
      this.phone = event.target.value;
    },
    onChangeMail(event) {
      if (!event?.target) {
        return;
      }
      this.email = event.target.value;
    },
    onChangeContent(event) {
      if (!event?.target) {
        return;
      }
      this.content = event.target.value;
    },
    selectMotivation(motivation) {
      this.motivation = motivation;
      this.activeDropdown = '';
    },
    selectProduct(product) {
      this.product = product;
      this.isErrorState.product = false;
      this.activeDropdown = '';
      this.checkApplySubmitBtnDisabled();
    },
    selectExpectedParticipant(expectedParticipant) {
      this.expectedParticipant = expectedParticipant;
      this.isErrorState.expectedParticipant = false;
      this.activeDropdown = '';
      this.checkApplySubmitBtnDisabled();
    },
    selecCompanyType(companyType) {
      this.companyType = companyType;
      this.isErrorState.companyType = false;
      this.activeDropdown = '';
      this.checkApplySubmitBtnDisabled();
    },
    selectExpectedStartDate(expectedStartDate) {
      this.expectedStartDate = expectedStartDate;
      this.activeDropdown = '';
    },
    selectDepartment(department) {
      this.department = department;
      this.activeDropdown = '';
    },
    async applySubmit() {
      const requestBody = {
        product: this.product,
        expectedParticipants: this.expectedParticipant,
        companyType: this.companyType,
        company: this.company,
        name: this.name,
        phone: this.phone,
        email: this.email,
        expectedStartDate: this.expectedStartDate === '예상 교육 시작일 (선택항목)' ? null : this.expectedStartDate,
        department: this.department === '담당 업무 (선택항목)' ? null : this.department,
        motivation: this.motivation === '링고라를 알게된 계기 (선택항목)' ? null : this.motivation,
        content: this.content,
        marketingConsent: this.isMarketingChecked ? 1 : 0,
      };
      try {
        const response = await axios.post('https://tutoring.co.kr/home/api/v1/home/sendMailB2BPart', requestBody);

        if (response.status === 200) {
          // 성공적으로 이메일을 보냈을 때의 처리
          alert('성공적으로 처리되었습니다.');
          this.$router.push('/');
        } else {
          // 이메일 보내기 실패 또는 다른 상태 코드일 때의 처리
          console.log(response.data);
        }
      } catch (error) {
        // 네트워크 요청 실패 등의 오류 처리
        console.log(error);
      }
    },

    onClickAplySubmitBtn() {
      this.applySubmit();
    },

    onClickTermAgree() {
      this.isTermChecked = !this.isTermChecked;
      this.checkApplySubmitBtnDisabled();
    },

    onMarketingConsent() {
      this.isMarketingChecked = !this.isMarketingChecked;
      this.checkApplySubmitBtnDisabled();
    },

    onBlurCompany() {
      if (!this.company) {
        this.isErrorState.company = true;
      } else {
        this.isErrorState.company = false;
      }
      this.checkApplySubmitBtnDisabled();
    },

    onBlurName() {
      if (!this.name) {
        this.isErrorState.name = true;
      } else {
        this.isErrorState.name = false;
      }
      this.checkApplySubmitBtnDisabled();
    },

    onBlurPhone() {
      if (!this.phone) {
        this.isErrorState.phone = true;
      } else {
        const p = this.phone.split('-').join('');
        const regPhone = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
        if (!regPhone.test(p)) {
          this.isErrorState.phone = true;
        } else {
          this.isErrorState.phone = false;
        }
      }
      this.checkApplySubmitBtnDisabled();
    },

    onBlurEmail() {
      const reg_email = /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(.[0-9a-zA-Z_-]+){1,2}$/;
      if (!this.email) {
        this.isErrorState.email = true;
      } else if (!reg_email.test(this.email)) {
        this.isErrorState.email = true;
      } else {
        this.isErrorState.email = false;
      }
      this.checkApplySubmitBtnDisabled();
    },

    onBlurContent() {
      if (!this.content) {
        this.isErrorState.content = true;
      } else {
        this.isErrorState.content = false;
      }
      this.checkApplySubmitBtnDisabled();
    },

    checkApplySubmitBtnDisabled() {
      const reg_email = /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      const regPhone = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;

      const validations = [
        { condition: this.product === '문의 교육 상품' },
        { condition: this.expectedParticipant === '예상 교육 인원' },
        { condition: this.companyType === '기업 유형' },
        { condition: !this.company },
        { condition: !this.name },
        { condition: !this.phone || !regPhone.test(this.phone.replace(/-/g, '')) },
        { condition: !this.email || !reg_email.test(this.email) },
        { condition: !this.content },
        { condition: !this.isTermChecked },
      ];

      const hasError = validations.some(({ condition }) => condition);
      this.isError = hasError;
      return this.isError;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.observeVideo();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    curTabPosition() {
      if (this.windowWidth > 469) return 0;
      if (this.windowWidth > 440) {
        switch (this.curTab) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            return 0;
          case 6:
            return this.windowWidth - 469;
          default:
            return 0;
        }
      }
      if (this.windowWidth >= 3) {
        switch (this.curTab) {
          case 1:
          case 2:
          case 3:
          case 4:
            return 0;
          case 5:
            return 0;
          case 6:
            return this.windowWidth - 445;
          default:
            return 0;
        }
      }
      switch (this.curTab) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 0;
        case 6:
          return this.windowWidth - 469;
        default:
          return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin pc($minimum_width: 822px) {
  @media (min-width: 822px) {
    @content;
  }
}

@mixin tablet($minimum_width: 821px) {
  @media (max-width: 821px) {
    @content;
  }
}

@mixin mobile($minimum_width: 480px) {
  @media (max-width: 480px) {
    @content;
  }
}

$base-url: '//res.tutoring.co.kr/res/images/web/b2b/';

#tutoringContent {
  overflow: hidden;
}
section {
  @include pc {
    padding: 130px 0;
  }
  @include tablet {
    padding: 100px 0;
  }
  @include mobile {
    padding: 50px 0 0 0;
  }
}
.banner {
  width: 100%;
  height: 380px;
  text-align: center;
  display: flex;
  color: #ffffff;
  background-size: cover;

  @include pc {
    background-image: url($base-url + 'img_top_w1600.png');
  }
  @include tablet {
    background-image: url($base-url + 'img_top_w768.png');
  }
  @include mobile {
    background-image: url($base-url + 'img_top_w360.png');
  }

  .inner {
    margin: auto;
    max-width: 964px;
  }
  .main__desc {
    font-weight: 700;
    &.mobile {
      display: none;
    }

    @include pc {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 12px;
    }
    @include tablet {
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 15px;
    }
    @include mobile {
      display: none;
      &.mobile {
        display: block;
      }
    }
  }
  .sub__desc {
    &.mobile {
      display: none;
    }
    @include pc {
      font-size: 19px;
    }
    @include tablet {
      font-size: 16px;
    }
    @include mobile {
      display: none;
      &.mobile {
        display: block;
      }
    }
  }
}
.company {
  background-color: #f1f0f5;
  text-align: center;
  color: #121214;

  .select__tab {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    @include pc {
      width: 884px;
      padding-bottom: 150px;
    }
    @include tablet {
      width: 668px;
      padding-bottom: 100px;
    }
    @include mobile {
      width: 320px;
      padding-bottom: 70px;
      gap: 10px;
    }
  }
  .button {
    border-radius: 23.85px;
    padding: 40px 35px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    position: relative;

    @include pc {
      width: 400px;
      height: 142px;
    }
    @include tablet {
      width: 210px;
      height: 110px;
    }
    @include mobile {
      width: 320px;
      height: 91px;
      padding: 15px 20px 20px 20px;
      text-align: center;
      justify-content: center;

      &:after {
        content: '';
        position: absolute;
        bottom: 14%;
        left: 41%;
        width: 8px;
        height: 8px;
        margin: -1px 0 1px 13px;
        border-top: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
        vertical-align: middle;
        box-sizing: border-box;
        transform: rotate(135deg) translateY(50%);
      }
    }

    .title {
      @include pc {
        font-size: 26px;
      }
      @include tablet {
        font-size: 20px;
      }
      @include mobile {
        font-size: 16px;
      }
    }
    .shortcut {
      .text {
        font-size: 13px;
        @include mobile {
          display: none;
        }
      }
    }

    &:nth-child(1) {
      background-color: #5d04d9;
      color: #ffffff;
    }
    &:nth-child(2) {
      background-color: #ffffff;
      color: #121214;
      &:after {
        @include mobile {
          content: '';
          position: absolute;
          bottom: 14%;
          left: 41%;
          width: 8px;
          height: 8px;
          margin: -1px 0 1px 13px;
          border-top: 2px solid #121214;
          border-right: 2px solid #121214;
          vertical-align: middle;
          box-sizing: border-box;
          transform: rotate(135deg) translateY(50%);
        }
      }
    }
    &:nth-child(3) {
      background-color: #06bf9d;
      color: #ffffff;
    }
    &:nth-child(2) :after {
      border-top: 2px solid #121214;
      border-right: 2px solid#121214;
    }
    :after {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      width: 8px;
      height: 8px;
      margin: -1px 0 1px 13px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      vertical-align: middle;
      box-sizing: border-box;
      transform: rotate(45deg) translateY(-50%);
      @include mobile {
        display: none;
      }
    }
  }
  &__title {
    font-weight: 700;
    font-size: 34px;
    &.mobile {
      display: none;
    }

    @include pc {
      font-size: 34px;
    }
    @include tablet {
      font-size: 27px;
    }
    @include mobile {
      font-size: 25px;
      line-height: 32px;
      display: none;
      &.mobile {
        display: block;
      }
    }
  }
  &__desc {
    &.mobile {
      display: none;
    }
    @include pc {
      font-size: 25px;
      margin: 20px 0 50px 0;
    }
    @include tablet {
      font-size: 15px;
      margin: 15px 0 30px 0;
    }
    @include mobile {
      font-size: 15px;
      margin: 20px 0 30px 0;
      display: none;
      &.mobile {
        display: block;
      }
    }
  }

  .logo {
    background-size: contain;
    margin: 0 auto;
    &.mobile {
      display: none;
    }
    @include pc {
      background-image: url($base-url + 'img_ref_w1600.png');
      width: 964px;
      height: 970px;
    }
    @include tablet {
      background-image: url($base-url + 'img_ref_w768.png');
      width: 688px;
      height: 672px;
      background-repeat: no-repeat;
    }
    @include mobile {
      background-image: url($base-url + 'img_ref_w360.png');
      width: 100%;
      background-size: cover;
      background-color: #ffffff;
      display: none;
      &.mobile {
        display: block;
        background-position-x: center;
      }
    }
  }
  .more {
    @include mobile {
      width: 100%;
      height: 80px;
      background-color: #ffffff;
      position: relative;
      &__icon {
        content: '';
        position: absolute;
        top: 15%;
        left: calc(50% - 20px);
        width: 20px;
        height: 19px;
        border-top: 2px solid #85808d;
        border-right: 2px solid #85808d;
        vertical-align: middle;
        box-sizing: border-box;
        transform: rotate(135deg) translateY(-50%);
      }
    }
  }
}
.mobile-section {
  background-color: #371469;
  & .contents-wrap {
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    .number {
      @include pc {
        font-size: 40px;
      }
      @include tablet {
        font-size: 32px;
      }
      @include mobile {
        font-size: 32px;
      }
    }
    .text {
      @include pc {
        font-size: 50px;
        margin: 30px 0 100px 0;
      }
      @include tablet {
        font-size: 32px;
        margin: 22px 0 80px 0;
      }
      @include mobile {
        font-size: 28px;
      }
    }
    .title__desc {
      margin-bottom: 10px;

      &.mobile {
        display: none;
      }
      @include pc {
        font-size: 30px;
      }
      @include tablet {
        font-size: 24px;
      }
      @include mobile {
        font-size: 24px;
        display: none;
        &.mobile {
          display: block;
        }
      }
    }
    .sub__desc {
      font-weight: 400;
      margin-bottom: 50px;
      @include pc {
        font-size: 19px;
        line-height: 28px;
      }
      @include tablet {
        font-size: 14px;
        line-height: 21px;
      }
      @include mobile {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 30px;
      }
    }
    .video_desc {
      width: 710px;
      border-radius: 20px;
      margin-bottom: 104px;

      @include tablet {
        width: 326px;
        margin-bottom: 40px;
      }
    }
    .img__desc {
      display: flex;
      gap: 50px;
      justify-content: center;
      @include tablet {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        img {
          margin-bottom: 0;
        }
        &:last-child {
          margin-bottom: 80px;
        }
      }
    }
    img {
      width: 330px;
      margin-bottom: 140px;
      border-radius: 20px;
      @include mobile {
        width: 240px;
        margin-bottom: 80px;
      }
    }
    .img12 {
      width: 410px;
      @include mobile {
        width: 300px;
        margin-bottom: 80px;
      }
    }
  }
  .contact-wrap {
    display: flex;
    justify-content: center;
  }
  .contact-button {
    width: 260px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 700;
    color: #3f3d44;
    @include pc {
      margin-bottom: 0;
    }
    @include tablet {
      margin-bottom: 0;
    }
    @include mobile {
      margin-bottom: 110px;
    }
  }
}
.lecture {
  background-color: #f1f0f5;

  .contents-wrap {
    color: #121214;
    font-weight: 700;
    text-align: center;
    .number {
      @include pc {
        font-size: 40px;
      }
      @include tablet {
        font-size: 32px;
      }
      @include mobile {
        font-size: 32px;
      }
    }
    .text {
      @include pc {
        font-size: 50px;
        margin: 30px 0 100px 0;
      }
      @include tablet {
        font-size: 32px;
        margin: 22px 0 80px 0;
      }
      @include mobile {
        font-size: 28px;
      }
    }
    .title__desc {
      margin-bottom: 50px;
      &.mobile {
        display: none;
      }
      @include pc {
        font-size: 30px;
      }
      @include tablet {
        font-size: 24px;
      }
      @include mobile {
        font-size: 24px;
        display: none;
        &.mobile {
          display: block;
        }
      }
    }
  }
  .container {
    margin: 0 auto;
    overflow: scroll;
    overflow-y: hidden;
    &__wrapper {
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      @include pc {
        width: 966px;
      }
      width: 676px;
      @include mobile() {
        margin-left: 20px;
      }
    }
  }
  .detail {
    background-color: #ffffff;
    border-radius: 30px;
    justify-content: space-between;
    @include pc {
      width: 300px;
      height: 288px;
    }
    @include tablet {
      width: 210px;
      height: 248px;
    }
    @include mobile {
      font-size: 24px;
    }
    .title {
      font-weight: 700;
      font-size: 20px;
      padding: 30px 30px 14px 30px;
      @include pc {
        font-size: 26px;
        padding: 40px 50px 16px 50px;
      }
    }
    ul {
      list-style: disc;
      padding: 0 30px 50px 30px;
      line-height: 21px;
      font-size: 15px;
      @include pc {
        padding: 0 50px;
        line-height: 24px;
      }
    }
  }
}
.introduce {
  padding-top: 80px;
  text-align: center;
  &__header {
    max-width: 960px;
    margin: 0 auto;
    .sub-title {
      color: #333;
    }
  }
  &__body {
    margin-top: 50px;
    &__tab__wrapper {
      padding: 24px;
      @include mobile {
        padding-left: 0;
      }
    }
    &__tab {
      margin: 0 auto;
      position: relative;
      display: flex;
      transition: left 0.2s;
      max-width: 960px;
      overflow-x: scroll;
      overflow-y: hidden;
      &__item {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 133px;
        height: 69px;
        border-bottom: 1px solid #d8d8d8;
        color: #999;
        font-size: 13px;
        letter-spacing: -1px;
        box-sizing: border-box;
        cursor: pointer;

        &.active {
          border-bottom: 2px solid #5d04d9;
          color: #121214;
          font-weight: 600;
        }
      }
    }
  }
  &__link {
    display: inline-block;
    color: #6759ef;
    font-size: 16px;
    line-height: 1.6em;
    letter-spacing: -1px;
    &::after {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      margin: -2px 0 2px 5px;
      background: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/ico_home_3_190402.png) no-repeat -220px -110px;
      background-size: 420px 400px;
      vertical-align: middle;
    }
  }
  &__content {
    display: flex;
    letter-spacing: -1px;
    flex-direction: column;
    margin: 0 auto;
    @include pc {
      width: 966px;
      height: 1000px;
    }
    @include tablet {
      width: 80%;
      height: 1000px;
    }
    @include mobile {
      width: 320px;
      height: 1100px;
    }
  }
  &__sub {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2em;
    letter-spacing: -1px;
    white-space: nowrap;
  }
  &__text {
    padding: 0 24px;
  }
  &__point {
    margin-top: 20px;
    margin-bottom: 25px;
    color: #6759ef;
    font-size: 15px;
    line-height: 1.48em;
  }
  &__desc {
    color: #666;
    font-size: 15px;
    line-height: 1.8em;
    br {
      display: block;
    }
  }

  &__swiper {
    overflow: visible;
    margin-top: 26px;
    width: 100%;
  }
  &__image {
    height: 100%;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    &__wrapper {
      overflow: hidden;
      height: 100%;
    }
    @include pc {
      width: 966px;
      &--00 {
        background-image: url($base-url + 'img_course_w1600_01.png');
      }
      &--01 {
        background-image: url($base-url + 'img_course_w1600_02.png');
      }
      &--02 {
        background-image: url($base-url + 'img_course_w1600_03.png');
      }
      &--03 {
        background-image: url($base-url + 'img_course_w1600_04.png');
      }
      &--04 {
        background-image: url($base-url + 'img_course_w1600_05.png');
        background-size: cover;
      }
    }
    @include tablet {
      &--00 {
        background-image: url($base-url + 'img_course_w768_01.png');
      }
      &--01 {
        background-image: url($base-url + 'img_course_w768_02.png');
      }
      &--02 {
        background-image: url($base-url + 'img_course_w768_03.png');
      }
      &--03 {
        background-image: url($base-url + 'img_course_w768_04.png');
      }
      &--04 {
        background-image: url($base-url + 'img_course_w768_05.png');
        width: 100%;
      }
    }
    @include mobile {
      &--00 {
        background-image: url($base-url + 'img_course_w360_01.png');
      }
      &--01 {
        background-image: url($base-url + 'img_course_w360_02.png');
      }
      &--02 {
        background-image: url($base-url + 'img_course_w360_03.png');
      }
      &--03 {
        background-image: url($base-url + 'img_course_w360_04.png');
      }
      &--04 {
        background-image: url($base-url + 'img_course_w360_05.png');
      }
    }
  }
}
.chat {
  background-color: #371469;
  .contents-wrap {
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    .number {
      @include pc {
        font-size: 40px;
      }
      @include tablet {
        font-size: 32px;
      }
      @include mobile {
        font-size: 32px;
      }
    }
    .text {
      @include pc {
        font-size: 50px;
        margin: 30px 0 100px 0;
      }
      @include tablet {
        font-size: 32px;
        margin: 22px 0 80px 0;
      }
      @include mobile {
        font-size: 28px;
      }
    }
    .chat__intro {
      width: 380px;

      @include mobile {
        width: 244px;
      }
      &__wrap {
        width: 501px;
        height: 194px;
        background-color: #5d04d9;
        border-radius: 30px;
        margin: 49px auto 0 auto;
        padding: 25px 104.5px;

        @include mobile {
          width: 280px;
          height: 168px;
          padding: 30px 20px;
        }

        ul {
          list-style: disc;
          font-size: 19px;
          text-align: left;
          line-height: 28px;
          font-weight: 400;
          @include mobile {
            font-size: 14px;
            line-height: 21px;
            margin-left: 20px;
          }
        }
      }
      &__title {
        font-size: 30px;
        margin-bottom: 20px;

        @include mobile {
          font-size: 24px;
          margin-bottom: 10px;
        }
      }
    }
    .chat__desc {
      font-style: normal;
      text-align: center;
      margin: 140px 0 10px 0;
      @include pc {
        font-size: 30px;
        line-height: 40px;
      }
      @include tablet {
        font-size: 24px;
        line-height: 32px;
      }
      @include mobile {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .chat__guide {
      font-style: normal;
      font-weight: 400;
      &.mobile {
        display: none;
      }
      @include pc {
        font-size: 19px;
        line-height: 28px;
      }
      @include tablet {
        font-size: 14px;
        line-height: 21px;
      }
      @include mobile {
        font-size: 14px;
        line-height: 21px;
        display: none;
        &.mobile {
          display: block;
        }
      }
    }
  }
  .answer {
    width: 710px;
    margin: 40px auto 140px auto;
    display: flex;
    @include mobile {
      display: contents;
    }

    img {
      width: 220px;
    }
  }
  .topic-img {
    width: 360px;
    margin-bottom: 60px;
  }
  .contact-wrap {
    display: flex;
    justify-content: center;
  }
  .contact-button {
    width: 260px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 700;
    color: #3f3d44;
    @include pc {
      margin-bottom: 130px;
    }
    @include tablet {
      margin-bottom: 100px;
    }
    @include mobile {
      margin-bottom: 110px;
    }
  }
}
.service {
  background-color: #f1f0f5;
  text-align: center;
  padding-top: 0px;
  @include mobile {
    padding-top: 80px;
  }
  &__wrap {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    @include pc {
      width: 966px;
    }
    @include tablet {
      width: 476px;
      display: block;
    }
    @include mobile {
      padding-bottom: 80px;
      display: flex;
      margin-left: 20px;
      overflow-x: scroll;
      overflow-y: hidden;
    }
    .item__wrapper {
      display: flex;
    }
  }
  &__item {
    width: 145px;
    height: 213px;
    padding: 30px 40px 40px 30px;
    background-color: #ffffff;
    border-radius: 26px;
    text-align: left;
    position: relative;
    margin-right: 27px;
    @include tablet {
      margin-bottom: 27px;
    }
    .title {
      font-weight: 700;
      font-size: 21px;
      line-height: 28px;
      margin-bottom: 14px;
      position: relative;
    }
    .desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    img {
      width: 58px;
      position: absolute;
      right: 40px;
      bottom: 40px;
    }
  }
  &__title {
    &.mobile {
      display: none;
    }
    font-weight: 700;

    @include pc {
      font-size: 34px;
    }
    @include tablet {
      font-size: 27px;
    }
    @include mobile {
      font-size: 25px;
      display: none;
      &.mobile {
        display: block;
      }
    }
  }
  &__desc {
    margin: 20px 0 50px 0;
    &.mobile {
      display: none;
    }
    @include mobile {
      display: none;
      &.mobile {
        display: block;
      }
    }
  }
}
.pride {
  padding: 60px 0px;
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-title {
    color: #c89546;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 51px;
    text-align: center;

    &__no1 {
      display: block;
      font-size: 40px;
      line-height: 50px;
      padding-bottom: 8px;

      @include mobile {
        font-size: 27px;
        line-height: 34px;
      }
    }
  }

  .img {
    width: 228px;
  }

  &__img {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    li:nth-child(1) {
      margin-top: 50px;
      width: 176.47px;
      height: 50.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2023_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }

    li:nth-child(2) {
      margin: 20px auto 0;
      width: 176.47px;
      height: 48.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2021_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }

    li:nth-child(3) {
      margin: 20px auto 0;
      width: 176.47px;
      height: 49.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2020_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }

    li:nth-child(4) {
      margin: 20px auto 0;
      width: 176.47px;
      height: 50.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2019_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }
  }
}

@media (min-width: 768px) {
  .pride {
    padding: 60px 0px;
    background: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      color: #c89546;
      font-weight: 400;
      font-size: 15.71px;
      line-height: 20px;
      padding-bottom: 60px;
      text-align: center;

      &__no1 {
        display: block;
        font-size: 46px;
        line-height: 58px;
        padding-bottom: 8px;
      }
    }

    .img {
      width: 235px;
      margin-bottom: 40px;
    }

    &__img {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      li:nth-child(1) {
        margin: 0 20px;
        width: 176.47px;
        height: 50.83px;
        background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2019_mo.png) no-repeat 50%;
        background-size: contain;
        opacity: 0.7;
      }

      li:nth-child(2) {
        margin: 0 20px;
        width: 176.47px;
        height: 48.83px;
        background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2020_mo.png) no-repeat 50%;
        background-size: contain;
        opacity: 0.7;
      }

      li:nth-child(3) {
        margin: 0 20px;
        width: 176.47px;
        height: 48.83px;
        background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2021_mo.png) no-repeat 50%;
        background-size: contain;
        opacity: 0.7;
      }

      li:nth-child(4) {
        margin: 0 20px;
        width: 176.47px;
        height: 48.83px;
        background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2023_mo.png) no-repeat 50%;
        background-size: contain;
        opacity: 0.7;
      }
    }
  }
}
.contact {
  width: 320px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 185px;
  @include pc {
    width: 638px;
  }
  &__title {
    font-weight: 700;
    font-size: 34px;
  }
  &__desc {
    margin: 20px 0 50px 0;
  }
  .b2b-form__wrapper {
    margin-right: auto;
    margin-left: auto;
    text-align: left;
    box-sizing: border-box;
  }
  .b2b-form__item {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
  }
  .b2b-form__label,
  .b2b-form__title {
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    font-size: 0;
  }
  .b2b-form__short {
    font-size: 12px;
    margin-top: 40px;
  }
  .b2b-form__dropdown .dropdown-main {
    position: relative;
    width: 100%;
    height: 52px;
    padding: 0 20px;
    border: 1px solid #ccc;
    color: #333;
    font-size: 16px;
    text-align: left;
    letter-spacing: -1px;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;
    transition: border-color 0.15s;
    margin: 10px 0;
    @include pc {
      height: 60px;
      border-radius: 10px;
      font-size: 18px;
      padding: 0 32px;
    }
  }
  .b2b-form__dropdown .dropdown-main.not-selected {
    border: 1px solid #ccc;
    color: #999999;
  }
  .dropdown-main.not-selected.error-border {
    border: 1px solid red;
  }
  .b2b-form__dropdown.open .dropdown-main {
    border-color: #333;
  }
  .b2b-form__dropdown.open .dropdown-list {
    width: 320px;
    display: block;
    z-index: 1;
    border-radius: 6px;
    @include pc {
      width: 638px;
      border-radius: 10px;
    }
  }
  .b2b-form__dropdown .dropdown-sub {
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    padding: 0 10px;
    font-size: 16px;
    border-radius: 6px;
    color: #333;
    background-color: #ffffff;
    border-radius: 10px;
    text-align: left;
    @include pc {
      height: 60px;
      padding: 0 20px;
      font-size: 18px;
      border-radius: 10px;
    }
  }
  .b2b-form__dropdown .dropdown-list {
    display: none;
    position: absolute;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
  }
  .b2b-form__dropdown .dropdown-main:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    width: 16px;
    height: 16px;
    background: url(//res.tutoring.co.kr/res/images/web/ico/ico_arrow_down.png) no-repeat 50% 0;
    background-size: 100%;
    transform: translateY(-50%);
  }
  .b2b-form__item2 .b2b-form__label {
    position: static;
    display: block;
    margin-top: 40px;
    margin-bottom: 16px;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: -1px;
  }
  .b2b-form__textarea {
    display: block;
    width: 100%;
    height: 160px;
    padding: 20px;
    border: 1px solid #ccc;
    color: #333;
    font-size: 16px;
    letter-spacing: -1px;
    box-sizing: border-box;
    border-radius: 6px;
    resize: none;
    outline: none;
    @include pc {
      border-radius: 10px;
    }
  }
  .b2b-form__item3 .b2b-form__label {
    position: static;
    display: block;
    margin-top: 40px;
    margin-bottom: 4px;
    color: #333;
    font-size: 18px;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: -1px;
  }
  .b2b-form__item3 .b2b-form__desc {
    position: static;
    display: block;
    color: #333;
    font-size: 14x;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: -1px;
  }
  .b2b-form__confirm {
    overflow: hidden;
    position: relative;
    margin-top: 16px;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .b2b-form__checkbox {
    position: absolute;
    top: -50px;
    left: 0;
  }
  .b2b-form__confirm .b2b-form__label {
    position: static;
    color: #3f3d44;
    font-size: 14px;
    line-height: 1.6em;
    letter-spacing: -1px;
    font-weight: 600;
    vertical-align: top;
    margin-top: 0px;
    margin-bottom: 0px;
    cursor: pointer;
  }
  .b2b-form__submit {
    width: 100%;
    height: 50px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    background-color: #5d04d9;
    border-radius: 10px;

    &.disabled {
      background: #f1f0f5;
      color: #85808d;
    }
  }
}
.contact {
  &__title {
    font-weight: 700;
    font-size: 34px;

    @include pc {
      font-size: 34px;
    }
    @include tablet {
      font-size: 27px;
    }
    @include mobile {
      font-size: 25px;
      line-height: 32px;
    }
  }
  &__desc {
    @include pc {
      font-size: 25px;
      margin: 20px 0 50px 0;
    }
    @include tablet {
      font-size: 15px;
      margin: 15px 0 30px 0;
    }
    @include mobile {
      font-size: 15px;
      margin: 20px 0 30px 0;
    }
  }
}

.custom-radio {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #d6d3dd;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.1s;
  background-color: white;
}

.custom-radio:active {
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.custom-radio:checked {
  background-color: #5d04d9;
  border-color: #5d04d9;
}

.custom-radio:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
}

.custom-radio:hover {
  border-color: #5d04d9;
}

.custom-radio:active {
  transform: scale(0.95);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.error-text {
  font-size: 16px;
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
}
.bottom-btn {
  position: fixed;
  bottom: 0;
  font-weight: bold;
  cursor: pointer;
  font-size: 24px;
  height: 60px;
  width: 100%;
  background-color: #5d04d9;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
</style>
