<template>
  <div class="input__wrapper">
    <input
      :placeholder="placeholder"
      :value="value"
      @input="changeInput"
      :class="{ 'error-input': isError }"
      @blur="focusOut"
    />
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: String,
    placeholder: String,
    isError: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    changeInput() {
      this.$emit('input');
    },
    focusOut() {
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 0 32px;
  border: 1px solid #ccc;
  color: #333;
  font-size: 18px;
  letter-spacing: -1px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.15s;

  @media (max-width: 767px) {
    height: 52px;
    padding: 0 20px;
    font-size: 16px;
    border-radius: 6px;
  }
}
input::placeholder {
  color: #999999;
  font-weight: 400;
  opacity: 1;
}

input:focus {
  outline: none;
  border: 1px solid #5d04d9;
}
.error-input {
  outline: none;
  border: 1px solid red;
}
</style>
