<template>
  <div class="checkBox--block" :class="{ checked: checked }" v-if="type === 'block'" @click="onClickChekBox" />
  <div class="checkBox--line" :class="{ checked: checked }" v-if="type === 'line'" @click="onClickChekBox" />
</template>

<script>
export default {
  name: 'CheckBox',
  emits: ['click'],
  props: {
    type: String,
    checked: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onClickChekBox() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.checkBox--block {
  overflow: hidden;
  background-color: #e8e6ed;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  background-image: url('//res.tutoring.co.kr/res/images/tps/icon/ic_check_outlined_16px.png');
  background-position: center center;
  background-size: 16px 16px;
  content: '';
}
.checkBox--block.checked {
  background-color: #5d04d9;
}
</style>
