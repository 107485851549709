import { render } from "./Program.vue?vue&type=template&id=1e0a5700&scoped=true"
import script from "./Program.vue?vue&type=script&lang=js"
export * from "./Program.vue?vue&type=script&lang=js"

import "./Program.vue?vue&type=style&index=0&id=1e0a5700&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1e0a5700"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1e0a5700"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1e0a5700', script)) {
    api.reload('1e0a5700', script)
  }
  
  module.hot.accept("./Program.vue?vue&type=template&id=1e0a5700&scoped=true", () => {
    api.rerender('1e0a5700', render)
  })

}

script.__file = "src/page/b2b/Program.vue"

export default script