import { render } from "./CheckBox.vue?vue&type=template&id=8937ca90&scoped=true"
import script from "./CheckBox.vue?vue&type=script&lang=js"
export * from "./CheckBox.vue?vue&type=script&lang=js"

import "./CheckBox.vue?vue&type=style&index=0&id=8937ca90&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-8937ca90"
/* hot reload */
if (module.hot) {
  script.__hmrId = "8937ca90"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8937ca90', script)) {
    api.reload('8937ca90', script)
  }
  
  module.hot.accept("./CheckBox.vue?vue&type=template&id=8937ca90&scoped=true", () => {
    api.rerender('8937ca90', render)
  })

}

script.__file = "src/page/b2b/components/CheckBox.vue"

export default script